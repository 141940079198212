.menu {
    position: relative;
    .submenu {
        display: none;
        // display: flex;
        flex-direction: column;

        position: absolute;
        background: #b50202;
        padding: 10px;
        gap: 10px;
        border-radius: 5px;
        top: calc(100% + 10px);
        z-index: 1;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 10px;
            background: transparent;
            top: -10px;
            left: 0;
            z-index: 0;
        }


    }

    &:hover {

        .submenu {
            display: flex;
        }
    }
}